<template>
  <div>
    <!-- Header -->
    <div class="header bg-light py-7 py-lg-8 pt-lg-2">
      <b-container>
        <div class="header-body text-center mb-4">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <Logo></Logo>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Connectez-vous</small>
              </div>
<!--              <validation-observer v-slot="{handleSubmit}" ref="formValidator">-->
                <b-form role="form" @submit.prevent="doLogin()">
                  <base-input alternative
                              class="mb-3"
                              name="Email"
                              :rules="{required: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Identifiant"
                              v-model="user.username">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Mot de passe"
                              v-model="user.password">
                  </base-input>

                  <!-- <b-form-checkbox v-model="model.rememberMe">Remember me</b-form-checkbox> -->
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                  </div>
                </b-form>
<!--              </validation-observer>-->
            </b-card-body>
          </b-card>
          <b-row class="mt-3" v-if="message">
            <b-col>
              <p class="text-red">{{message}}</p>
            </b-col>
          </b-row>
          <b-row class="mt-3 bottom-links">
            <b-col cols="6">
              <router-link to="/dashboard" class=""><small>Forgot password?</small></router-link>
            </b-col>
            <b-col cols="6" class="text-right">
              <router-link to="/register" class=""><small>Create new account</small></router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

  import AuthService from "@/services/AuthService";
  import Logo from "../../components/Logo";
  import {mapActions, mapGetters} from 'vuex';

  export default {
    data() {
      return {
        user: {
          username: '',
          password: ''
        },
        message:''
      };
    },
    components:{
      Logo
    },
    computed:{
      ...mapGetters({
        me: 'users/me'
      })
    },
    methods: {
      ...mapActions({
        getCurrentUser: 'users/getCurrentUser'
      }),
      doLogin() {
        AuthService.login(this.user)
          .then((response) => {
            if(response === 204){
              this.getCurrentUser()
                .then((response) => {
                  if (response === 200) {
                    this.$router.push('/dashboard');
                  } else {
                    this.message = 'Erreur lors de la connexion. Vérifiez vos identifiants'
                  }
                })
              .catch((error)=>{
                if (error.status === 403) {
                  this.message = 'Votre utilisateur n\'est pas encore activé.'
                }else {
                  this.message = 'Erreur lors de la connexion. Vérifiez vos identifiants'
                }
              })
            }else {this.message = 'Erreur lors de la connexion. Vérifiez vos identifiants'}
        })
        .catch(()=>{
          this.message = 'Erreur lors de la connexion. Vérifiez vos identifiants'
        });
      }
    }
  };
</script>

<style>
  .bottom-links a:hover{
    color:#5e72e4;
  }
</style>
